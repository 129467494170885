import React, { ReactNode } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { IonButton, IonIcon } from '@ionic/react';
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './CustomSwiper.css'; // יצירת קובץ CSS מותאם אישית

const CustomSwiper: React.FC<{
    children?: ReactNode;
}> = ({children}) => {
  return (
    <div className="custom-swiper-container">
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        pagination={{ clickable: true }}
      >
        {children}
      </Swiper>
      
      {/* כפתורי ניווט מותאמים אישית */}
      <IonButton className="swiper-button-prev custom-swiper-button" fill="clear">
        <IonIcon icon={chevronBackOutline} />
      </IonButton>
      <IonButton className="swiper-button-next custom-swiper-button" fill="clear">
        <IonIcon icon={chevronForwardOutline} />
      </IonButton>
    </div>
  );
};

export default CustomSwiper;