import React, { useEffect, useState } from 'react';
import { IonAvatar, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenuToggle, IonPage, IonThumbnail, IonTitle, IonToolbar } from '@ionic/react';
import AppHeader from '../components/AppHeader';
import './Home.css';
import DefaultPage from '../components/DefaultPage';
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import { useGlobalContext } from '../contexts/GlobalContext';
import { contrastOutline, gridOutline, helpCircle, languageOutline, logOut, musicalNotes, pause, personCircle, play, settings, statsChart } from 'ionicons/icons';
import WordsModal from '../components/WordsModal';
import { Song, SongSentence, SpotifyTrack } from '../types/types.';

const SongPage: React.FC<RouteComponentProps> = (props) => {
  const { user, getSongData, generateLyricsSentences, isPlaying, nowPlaying, togglePlaySong, setNowPlaying } = useGlobalContext();
  const [showModal, setShowModal] = useState(false);
  const [songData, setSongData] = useState<Song | null>(null);
  const [songSentences, setSongSentences] = useState<SongSentence[] | null>(null);
  const { spotifyId } = useParams<{ spotifyId: string }>();
  useEffect(() => {
    console.log("from SongPage: ", user?.words)
    console.log(user)

    if (spotifyId) {
      fetchSongDetails()
    }
  }, [user]);
  const fetchSongDetails = async () => {
    const songDetails = await getSongData(spotifyId);

    if (songDetails) {
      setNowPlaying(songDetails)
      setSongData(songDetails)
      if (songDetails.lyrics) {
        const songSentencesResponse = await generateLyricsSentences(songDetails.lyrics);
        if (songSentencesResponse) {
          const responseToSentenceType = songSentencesResponse.sentences.map((s: any, i: number) => {
            return {
              id: -1, songId: songDetails.id, sentence: s.original, translation: s.translation, position: i + 1
            }
          })
          console.log("responseToSentenceType: ", responseToSentenceType)
          setSongSentences(responseToSentenceType)
        }
      }
      //check if has lyrics, has words...
      //if not, generate and save in user.songs after all data will be collected.
    }
  };
  return (
    <>
      <DefaultPage>
        <IonCard className="default-card">
          <IonContent>
            <h2 className='default-card__list-title'>{songData?.title}</h2>
            <div className="sentence-translation-list">
              {songSentences && songSentences.map((item, index) => (
                <div key={index} className="sentence-translation-item">
                  <p className="sentence">{item.sentence}</p>
                  <p className="translation">{item.translation}</p>
                </div>
              ))}
            </div>
          </IonContent>
        </IonCard>
      </DefaultPage>
      {user?.words && (
        <WordsModal words={user?.words} showModal={showModal} closeModal={() => setShowModal(false)} />
      )}
    </>
  );
};

export default SongPage;
