import React from 'react';
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonChip, IonIcon, IonButton, IonText, IonThumbnail } from '@ionic/react';
import { volumeHighOutline, bookOutline, star, addOutline, addCircle, language } from 'ionicons/icons';
import LanguageSelector, { languages } from './LanguageSelector';
import { useGlobalContext } from '../contexts/GlobalContext';
import { Word } from '../types/types.'
// export interface WordType {
//   id: string;
//   sourceWord: string;
//   translation: string;
//   exampleSentence: string;
//   exampleTranslation: string;
//   englishTranslation: string;
//   partOfSpeech: string;
//   synonyms: string[];
//   antonyms: string[];
//   difficultyLevel: 'beginner' | 'intermediate' | 'advanced';
//   categories: string[];
//   image?: string;
//   sourceWordLang: string;
// }


interface WordCardProps {
  word: Word;
  previewMode: string;
  onPlayPronunciation?: (word: string) => void;
  onAddToFavorites?: (word: string) => void;
  onShowDetails?: (word: string) => void;
}

const WordCard: React.FC<WordCardProps> = ({
  word,
  previewMode,
  onPlayPronunciation,
  onAddToFavorites,
  onShowDetails
}) => {
  const { speakWord, saveWordToUser, user } = useGlobalContext();

  return (
    <IonCard className='word'>

      <IonCardHeader>
        <IonCardSubtitle>{word.translation}</IonCardSubtitle>
        <IonCardTitle>{word.sourceWord}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {/* <IonThumbnail slot="start"> */}
        <img src={word.image} alt={word.sourceWord} />
        {/* </IonThumbnail> */}
        <p><strong>Translation:</strong> {word.translation}</p>
        <p><strong>Example:</strong> {word.exampleSentence}</p>
        <p><em>{word.exampleTranslation}</em></p>

        <div>
          {word.categories ? word.categories.map((category, index) => (
            <IonChip key={index}>{category}</IonChip>
          )) : <></>}
        </div>

        <IonChip color={
          word.difficultyLevel === 'beginner' ? 'success' :
            word.difficultyLevel === 'intermediate' ? 'warning' :
              'danger'
        }>
          {word.difficultyLevel}
        </IonChip>
      </IonCardContent>

      <div className="ion-padding">
        <IonButton fill="clear" onClick={() => speakWord(word.sourceWord, word.sourceWordLang)}>
          <IonIcon icon={volumeHighOutline} slot="start" />
        </IonButton>
        <IonButton fill="clear" onClick={() => saveWordToUser({userId: (user && user.id || -1), wordId: word.id})}>
          <IonIcon icon={addOutline} slot="start" />
        </IonButton>
      </div>
      <audio id="audio-player" />
    </IonCard>
  );
};

export default WordCard;