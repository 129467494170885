import React, { useCallback, useEffect, useState } from 'react';
import { useGlobalContext } from '../contexts/GlobalContext';
import axios from 'axios';
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonList, IonItem, IonLabel, IonThumbnail, IonIcon, IonImg, IonSpinner } from '@ionic/react';
import { playCircleOutline, pauseCircleOutline, openOutline, eyeOutline, saveOutline, downloadOutline, gridOutline, addOutline, planetOutline, volumeHighOutline, add, play, chevronUp, chevronDown } from 'ionicons/icons';
// import './Playlist.css';
import { useHistory } from 'react-router-dom';
import { title } from 'process';
import { Song, SpotifyPlaylist, SpotifyTrack } from '../types/types.';
import SongsList from './SongsList';


export interface Playlist {
    id: string;
    name: string;
    description: string;
    tracks: { total: number };
    external_urls: { spotify: string }
    images: [{ url: '' }]
}

const Playlist: React.FC<{ playlists: SpotifyPlaylist[] }> = ({ playlists }) => {
    const { spotifyToken, saveSongWords, saveSong, updateSong, getSongData, saveUserSong, getUserPlaylists, getPlaylistTracks, speakWord, togglePlaySong, playingTrackId, isPlaying, getLyrics, generateLyricsVocabulary, selectedTrack, setSelectedTrack, saveWords } = useGlobalContext();
    const [selectedPlaylist, setSelectedPlaylist] = useState<Playlist | null>(null);
    const [playlistTracks, setPlaylistTracks] = useState<SpotifyTrack[]>([]);
    const [showPlaylists, setShowPlaylists] = useState<boolean>(true);
    const [cardTitle, setCardTitle] = useState<string>("Spotify Playlists");
    const [showPlaylistTracks, setShowPlaylistTracks] = useState<boolean>(false);
    const [showListItems, setShowListItems] = useState<boolean>(true);
    const handlePlaylistSelect = async (playlist: SpotifyPlaylist) => {
        console.log("handlePlaylistSelect: ", playlist)
        if (spotifyToken && playlist.id) {
            const tracks = await getPlaylistTracks(spotifyToken, playlist.id)
            if (tracks) {
                setSelectedPlaylist(playlist)
                setPlaylistTracks(tracks)
                setShowPlaylistTracks(true)
                setShowListItems(false)
            }
        }
    };

    return (
        <div>
            {showPlaylists && (
                <div className='default-card-wrapper'>
                    <div className='d-flex justify-content-between'>
                        <h2 className='default-card__list-title'>My Playlists</h2>
                        <IonButton onClick={() => setShowListItems(!showListItems)} className='toggle-btn' fill='clear'>
                            <IonIcon icon={showListItems ? chevronUp : chevronDown} />
                        </IonButton>
                    </div>
                    {showListItems && (
                        <IonList>
                            {playlists.filter(p => p.name !== '').map((pl) => (
                                <IonItem key={pl.id}>
                                    <div className='d-flex default-card__content-right' onClick={() => handlePlaylistSelect(pl)}>
                                        <IonThumbnail style={{ heigth: '' }}>
                                            <img src={pl.images[0]?.url} alt={pl.name} />
                                        </IonThumbnail>
                                        <IonLabel style={{ width: '80%', cursor: 'pointer' }}>
                                            <div className='d-flex align-items-center'>
                                                <h2 className='f-bold' style={{ cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{pl.name}</h2>
                                            </div>
                                            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                <i>{pl.description}</i>
                                            </div>
                                        </IonLabel>
                                    </div>
                                    <IonButton href={pl.external_urls.spotify} target="_blank"
                                        rel="noopener noreferrer" className='action-btn' >
                                        <IonIcon icon={openOutline} />
                                    </IonButton>
                                </IonItem>
                            ))}
                        </IonList>
                    )}
                </div>
            )}
            {selectedPlaylist && showPlaylistTracks && playlistTracks.length > 0 && (
                <SongsList name={selectedPlaylist.name} tracks={playlistTracks} />
            )}


            {/* <div className='default-card-wrapper'>
                <div className='d-flex justify-content-between align-items-center'>
                    <p className='default-card__list-title'>{name}</p>
                    <IonButton onClick={toggleListView} className='toggle-btn' fill='clear'>
                        <IonIcon icon={showListItems ? chevronUp : chevronDown} />
                    </IonButton>
                </div>
                {showListItems && (
                    <div className='default-card__list-wrapper'>
                        <IonList>
                            {songs && songs.length > 0 && songs.map((song) => (
                                <IonItem key={song.spotifyId}>
                                    <div className='d-flex  default-card__content-right' style={{ width: '100%', padding: '8px' }}>
                                        <IonThumbnail style={{ heigth: '' }}>
                                            <img src={song.albumUrl} alt={song.title} />
                                        </IonThumbnail>
                                        <IonLabel style={{ width: '80%', cursor: 'pointer' }} onClick={() => navigateToItem(song.spotifyId, 'song')}>
                                            <div className='d-flex align-items-center' style={{ maxWidth: '150px' }}>
                                                <h2 className='f-bold' style={{ cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{song.title}</h2>
                                            </div>
                                            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                <i>{song.artist}</i>
                                            </div>
                                        </IonLabel>
                                    </div>
                                    <IonButton onClick={() => togglePlaySong(song)} className='action-btn'>
                                        <IonIcon icon={playingTrackId === song.spotifyId && isPlaying ? pause : play} />
                                    </IonButton>
                                </IonItem>
                            ))}
                            {tracks && tracks.length > 0 && tracks.map((track) => (
                                <IonItem key={track.id} >
                                    <div className='d-flex  default-card__content-right' style={{ width: '100%', padding: '8px' }}>
                                        <IonThumbnail style={{ heigth: '' }}>
                                            <img src={track.album.images[0]?.url} alt={track.name} />
                                        </IonThumbnail>
                                        <IonLabel style={{ width: '80%', cursor: 'pointer' }}>
                                            <div className='d-flex align-items-center' style={{ maxWidth: '150px' }}>
                                                <h2 className='f-bold' style={{ cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{track.name}</h2>
                                            </div>
                                            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                <i>{track.artists.map(artist => artist.name).join(', ')}</i>
                                            </div>
                                        </IonLabel>
                                        <IonButton onClick={() => togglePlaySong(track)} className='thumbnail-btn'>
                                            <IonIcon icon={playingTrackId === track.id && isPlaying ? pause : play} />
                                        </IonButton>
                                    </div>
                                    <IonButton onClick={() => handleSaveSong(track)} className=''>
                                        <IonIcon icon={addOutline} />
                                    </IonButton>
                                </IonItem>
                            ))}
                        </IonList>
                    </div>
                )}
            </div> */}
        </div>
    );
};

export default Playlist;

