import React, { createContext, useState, useContext, ReactNode, useEffect, useCallback } from 'react';
import { isPlatform } from '@ionic/react'
import axios from 'axios';
import { Playlist } from '../components/Playlist';
import { config } from '../config';


import { Song, User, Word, SpotifyTrack, SongSentence } from '../types/types.'
import { useHistory } from 'react-router';
interface GlobalContextType {
  user: User | null;
  selectedLanguage: string | null;
  selectedTrack: SpotifyTrack | null;
  setUser: (user: User | null) => void;
  setSelectedTrack: (song: SpotifyTrack | null) => void;
  setSelectedLanguage: (lang: string | null) => void;
  handleSpotifyLoginSuccess: (token: string) => Promise<any>;
  handleGoogleLoginSuccess: (token: string) => void;
  speakWord: (word: string, lang: string) => void;
  isMobile: boolean | null;
  showSearch: boolean | null;
  setShowSearch: (show: boolean) => void;
  getUserPlaylists: (token: string) => Promise<Playlist[]>;
  getPlaylistTracks: (token: string, playlistId: string) => Promise<SpotifyTrack[]>;
  generateLyricsSentences: (lyrics: string) => Promise<any>;
  getLyrics: (track: SpotifyTrack) => Promise<string>;
  generateLyricsVocabulary: (lyrics: string) => Promise<Word[]>;
  getPexelsImageForWord: (word: string) => Promise<string | null>;
  searchSong: (query: string) => Promise<SpotifyTrack[]>;
  allowedUsers: string[] | null;
  updateUser: (action: string, data: any) => void  //field: string, value: string
  spotifyToken: string | null;
  spotifyDeviceId: string | null;
  setSpotifyDeviceId: (deviceId: string) => void;
  togglePlaySong: (item: Song | SpotifyTrack) => void;
  isPlaying: boolean;
  playingTrackId: string;
  nowPlaying: Song | SpotifyTrack | null;
  setNowPlaying: (song: Song | SpotifyTrack) => void;
  getSongData: (spotifyId: string) => Promise<Song | null>;
  saveSong: (song: any) => Promise<any>;
  saveUserSong: (data: { userId: number, songId: number }) => Promise<any>;
  updateSong: (song: any) => Promise<any>;
  removeSongFromUser: (data: { userId: number, songId: number }) => Promise<any>;
  saveWords: (words: Word[]) => Promise<any>;
  saveWordToUser: (data: { userId: number, wordId: number }) => Promise<any>;
  removeWordFromUser: (data: { userId: number, wordId: number }) => Promise<any>;
  updateWord: (word: Word) => Promise<any>;
  getTopTracksByCountry: () => Promise<any>;
  getUserSongs: (userId: number) => Promise<Song[]>;
  getUserWords: (userId: number) => Promise<Word[]>;
  saveSongWords: (songId: number, words: Word[]) => Promise<any>;
  getSongWords: (songId: number) => Promise<Word[]>;
  theme: string;
  setTheme: (theme: string) => void;
  // handleSpotifyCallback: (theme: string) => void;
}

const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

export const GlobalContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const isMobile = isPlatform('mobile');
  const [user, setUser] = useState<User | null>(null);
  const [showSearch, setShowSearch] = useState<boolean>(!isMobile);
  const [selectedTrack, setSelectedTrack] = useState<SpotifyTrack | null>(null);
  const [allowedUsers, setAllowedUsers] = useState<string[] | null>(['taubeyana@gmail.com', 'yana148@gmail.com', 'yana.rozman@fleximind.ai']);
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>('en-US');

  const [spotifyToken, setSpotifyToken] = useState<string | null>(null);
  const [spotifyRefreshToken, setSpotifyRefreshToken] = useState<string | null>(null);
  const [spotifyDeviceId, setSpotifyDeviceId] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [nowPlaying, setNowPlaying] = useState<Song | SpotifyTrack | null>(null);
  const [playingTrackId, setPlayingTrackId] = useState<string>("");

  const [theme, setTheme] = useState<string>('theme-colored');
  // Use the correct API URL based on the environment
  const API_URL = config.apiUrl;

  // useEffect(() => {
  //   console.log("from globalcnx")
  //   const savedUser = localStorage.getItem('user');
  //   const savedToken = localStorage.getItem('spotifyToken');
  //   if (savedUser) {
  //     setUser(JSON.parse(savedUser));
  //   }
  //   if (savedToken) {
  //     setSpotifyToken(savedToken);
  //   }
  // }, []);

  const handleSpotifyLoginSuccess = async (token: string) => {
    localStorage.setItem('authToken', token);
    localStorage.setItem('authTokenProvider', "spotify");
    setSpotifyToken(token);
    try {
      const response = await axios.get('https://api.spotify.com/v1/me', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const spotifyUserData = response.data;
      console.log("spotifyUserData: ", spotifyUserData)
      if (spotifyUserData.id) {
        const savedUser = await getUserDetails(spotifyUserData.id, "getUserDataBySpotifyId")
        console.log("savedUser: ", savedUser)
        if (savedUser) {
          setUser(savedUser)
        }
        else {
          const createdUser = await createNewUser("createNewUserBySpotifyData", { id: -1, spotifyId: spotifyUserData.id, email: spotifyUserData.email, displayName: spotifyUserData.display_name })
          if (createdUser && createdUser.id > 0) {

            console.log("createdUser: ", createdUser)
            const newUser = await getUserDetails(createdUser.id.toString(), "getUserDataById")
            setUser(newUser)
          }
        }
      }

    } catch (error) {
      console.error('Error fetching Spotify user data:', error);
    }
};
  const getUserDetails = async (id: string, action: string): Promise<User | null> => {
    try {
      const response = await axios.post(
        `${API_URL}/usersData`,
        { action, data: id },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          withCredentials: false,
        }
      );
      if (response.data && response.data.length) {
        return response.data[0];
      }
    } catch (error) {
      console.error('Error in getUserDetails:', error);
    }
    return null;
  };
  const getUserSongs = async (userId: number): Promise<Song[]> => {
    try {
      const response = await axios.post(
        `${API_URL}/usersData`,
        { action: 'getUserSongs', data: userId },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          withCredentials: false,
        }
      );
      if (response.data) {
        return response.data;
      }
      return [];
    } catch (error) {
      console.error('Error in getUserSongs:', error);
      return [];
    }
  };
  const getUserWords = async (userId: number): Promise<Word[]> => {
    try {
      const response = await axios.post(
        `${API_URL}/usersData`,
        { action: 'getUserWords', data: userId },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          withCredentials: false,
        }
      );
      if (response.data) {
        return response.data;
      }
      return [];
    } catch (error) {
      console.error('Error in getUserWords:', error);
      return [];
    }
  };
  const createNewUser = async (action: string, data: any): Promise<User | null> => {
    try {
      const response = await axios.post(
        `${API_URL}/usersData`,
        { action, data },  // Parse the JSON string here
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          withCredentials: false,
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error in createNewUser:', error);
      return null;
    }
  };
  const updateUser = async (action: string, data: any) => {
    console.log("updateUser...")
    try {
      const response = await axios.post(
        `${API_URL}/usersData`,
        { action, data },  // Parse the JSON string here
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          withCredentials: false,
        }
      );
      if (response.data && response.data.length) {
        setUser(response.data[0]);
      }

    } catch (error) {
      console.error('Error in updateUser:', error);
      return null;
    }
    return null;
  };
  const togglePlaySong = async (track: Song | SpotifyTrack) => {
    setNowPlaying(track)
    const isSong = (track: Song | SpotifyTrack): track is Song => {
      return (track as Song).spotifyId !== undefined;
    };

    const id = isSong(track) ? track.spotifyId : track.id;

    if (!spotifyDeviceId) {
      console.error('Device ID not set yet!');
      return;
    }

    try {
      if (id === playingTrackId) {
        // Toggle play/pause for the current track
        const endpoint = isPlaying ? 'pause' : 'play';
        await fetch(`https://api.spotify.com/v1/me/player/${endpoint}?device_id=${spotifyDeviceId}`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${spotifyToken}`,
            'Content-Type': 'application/json',
          },
        });
        setIsPlaying(!isPlaying);
      } else {
        // Play a new track
        const trackUri = `spotify:track:${id}`;
        await fetch(`https://api.spotify.com/v1/me/player/play?device_id=${spotifyDeviceId}`, {
          method: 'PUT',
          body: JSON.stringify({
            uris: [trackUri],
          }),
          headers: {
            Authorization: `Bearer ${spotifyToken}`,
            'Content-Type': 'application/json',
          },
        });
        setPlayingTrackId(id.toString());
        setIsPlaying(true);
      }
    } catch (error) {
      console.error('Error toggling play/pause:', error);
    }
  }
  const speakWord = async (word: string, lang: string) => {
    const speech = new SpeechSynthesisUtterance(word);
    speech.lang = lang;
    window.speechSynthesis.speak(speech);
  };

  const handleGoogleLoginSuccess = async (token: string) => {
    console.log("Google token: ", token);
    localStorage.setItem('authToken', token);
    localStorage.setItem('authTokenProvider', "google");
    try {
      const response = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const googleUserData = response.data;
      // get user data from db. if user - setuser from db. 
      const savedUser = JSON.parse(localStorage.getItem('user') || '{}');
      if (savedUser.id === googleUserData.sub) {
        setUser(savedUser)
      }
      else {
        setUser(prevState => ({
          ...prevState!,
          id: googleUserData.sub, email: googleUserData.email, provider: 'google', name: googleUserData.given_name
        }));
        localStorage.setItem('user', JSON.stringify({ id: googleUserData.sub, email: googleUserData.email, provider: 'google', name: googleUserData.given_name }));
      }
    } catch (error) {
      console.error('Error fetching Google user data:', error);
    }
  };
  const getTopTracksByCountry = async (): Promise<any[]> => {
    console.log("getTopTracksByCountry...")
    console.log("spotifyToken...", spotifyToken)
    try {
      if (spotifyToken) {
        const searchResponse = await axios.get('https://api.spotify.com/v1/search', {
          headers: { 'Authorization': `Bearer ${spotifyToken}` },
          params: {
            q: `Top 50 - ${"Italy"}`,
            type: 'playlist',
            limit: 1
          }
        });

        const playlistId = searchResponse.data.playlists.items[0].id;
        // עכשיו נמשוך את השירים מהרשימה הראשונה
        const tracksResponse = await axios.get(`https://api.spotify.com/v1/playlists/${playlistId}/tracks`, {
          headers: { 'Authorization': `Bearer ${spotifyToken}` },
          params: { limit: 50, locale: "IT" } // מגביל ל-50 שירים
        });
        console.log("playlistResponse.data.playlists: ", tracksResponse.data.items)

        return tracksResponse.data.items.map((item: any) => item.track);
      }
    } catch (error) {
      console.error('Error fetching top tracks:', error);
      return [];
    }
    return [];
  };
  const getUserPlaylists = async (token: string): Promise<Playlist[]> => {
    try {
      const response = await axios.get('https://api.spotify.com/v1/me/playlists', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return response.data.items;
    } catch (error) {
      console.error('Error fetching user playlists:', error);
      return [];
    }
  };
  const getPlaylistTracks = async (token: string, playlistId: string): Promise<SpotifyTrack[]> => {
    try {
      const response = await axios.get(`https://api.spotify.com/v1/playlists/${playlistId}/tracks`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return response.data.items.map((item: any) => item.track);
    } catch (error) {
      console.error('Error fetching playlist tracks:', error);
      return [];
    }
  };
  const generateLyricsSentences = async (lyrics: string): Promise<any> => {
    console.log("generateLyricsSentences...")
    console.log("generateLyricsVocabulary...")
    try {
      const response = await axios.post(`${API_URL}/generateLyricsSentences`, {
        lyrics: lyrics,
        outputLang: 'hebrew'
      });
      return response.data;
    } catch (error) {
      console.error('Error generateLyricsSentences:', error);
      return [];
    }
  };
  const getLyrics = async (track: SpotifyTrack): Promise<string> => {
    console.log("getLyrics...")
    try {
      const response = await axios.post(`${API_URL}/getLyrics`, {
        artist: track.artists.map((artist: any) => artist.name).join(', '),
        songTitle: track.name
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        withCredentials: false, // Ensure this is set correctly
      });

      if (response.data.lyrics) {
        return response.data.lyrics
      }
      return "";
    } catch (error) {
      console.error('Error getLyrics:', error);
      return "";
    }
  };
  const generateLyricsVocabulary = async (lyrics: string): Promise<Word[]> => {
    console.log("generateLyricsVocabulary...")
    try {
      const response = await axios.post(`${API_URL}/generateLyricsVocabulary`, {
        lyrics: lyrics,
        outputLang: 'hebrew'
      });
      return await fetchImages(response.data);
    } catch (error) {
      console.error('Error generateLyricsVocabulary:', error);
      return [];
    }
  };
  const getPexelsImageForWord = async (word: string) => {
    console.log("getPexelsImageForWord... word: ", word)
    const accessKey = process.env.REACT_APP_PEXELS_KEY;
    const url = `https://api.pexels.com/v1/search?query=${encodeURIComponent(word)}&per_page=1`;

    try {
      const response = await axios.get(url, {
        headers: { Authorization: accessKey }
      });

      if (response.data.photos && response.data.photos.length > 0) {
        const photo = response.data.photos[0];
        return photo.src.medium;
      }
      return null;
    } catch (error) {
      console.error('Error fetching image from Pexels:', error);
      return null;
    }
  };
  const fetchImages = async (words: Word[]) => {

    return Promise.all(words.map(async (w) => {
      w.image = await getPexelsImageForWord(w.englishTranslation || "default picture");
      return w;
    }));
  };
  const searchSong = async (query: string): Promise<SpotifyTrack[]> => {
    try {
      const response = await axios.post(
        `${API_URL}/spotifySearch`,
        { artist: '', title: query },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          withCredentials: false, // Ensure this is set correctly
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error in searchSong:', error);
      return [];
    }
  };
  const getSongData = async (spotifyId: string): Promise<Song | null> => {
    try {
      const response = await axios.post(
        `${API_URL}/songsData`,
        { action: "getSongData", data: spotifyId },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          withCredentials: false,
        }
      );
      if (response.data && response.data.song) {
        return response.data.song;
      }
    } catch (error) {
      console.error('Error in getSongData:', error);
    }
    return null;
  };
  const saveSong = async (song: any) => {
    try {
      const response = await axios.post(`${API_URL}/songsData`, { action: 'saveSong', data: song });
      return response.data;
    } catch (error) {
      console.error('Error in saveSong:', error);
      throw error;
    }
  };

  const saveUserSong = async (data: { userId: number, songId: number }) => {
    try {
      const response = await axios.post(`${API_URL}/songsData`, { action: 'saveUserSong', data });
      return response.data;
    } catch (error) {
      console.error('Error in saveUserSong:', error);
      throw error;
    }
  };

  const updateSong = async (song: any) => {
    try {
      const response = await axios.post(`${API_URL}/songsData`, { action: 'updateSong', data: song });
      return response.data;
    } catch (error) {
      console.error('Error in updateSong:', error);
      throw error;
    }
  };

  const removeSongFromUser = async (data: { userId: number, songId: number }) => {
    try {
      const response = await axios.post(`${API_URL}/songsData`, { action: 'removeSongFromUser', data });
      return response.data;
    } catch (error) {
      console.error('Error in removeSongFromUser:', error);
      throw error;
    }
  };

  const saveWords = async (words: Word[]) => {
    try {
      const response = await axios.post(`${API_URL}/wordsData`, { action: 'saveWords', data: words });
      return response.data;
    } catch (error) {
      console.error('Error in saveWords:', error);
      throw error;
    }
  };

  const saveWordToUser = async (data: { userId: number, wordId: number }) => {
    try {
      const response = await axios.post(`${API_URL}/wordsData`, { action: 'saveWordToUser', data });
      return response.data;
    } catch (error) {
      console.error('Error in saveWordToUser:', error);
      throw error;
    }
  };
  const removeWordFromUser = async (data: { userId: number, wordId: number }) => {
    try {
      const response = await axios.post(`${API_URL}/wordsData`, { action: 'removeWordFromUser', data });
      return response.data;
    } catch (error) {
      console.error('Error in removeWordFromUser:', error);
      throw error;
    }
  };
  const updateWord = async (word: Word) => {
    try {
      const response = await axios.post(`${API_URL}/wordsData`, { action: 'updateWord', data: word });
      return response.data;
    } catch (error) {
      console.error('Error in updateWord:', error);
      throw error;
    }
  };
  const saveSongWords = async (songId: number, words: Word[]): Promise<any> => {
    try {
      const response = await axios.post(
        `${API_URL}/wordsData`,
        {
          action: 'saveSongWords',
          data: { songId, words }
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          withCredentials: false,
        }
      );

      if (response.data && response.data.success) {
        console.log("Song words saved and associated successfully");
        return response.data;
      } else {
        console.error("Failed to save and associate words with song", response.data);
        throw new Error(response.data.message || 'Failed to save and associate words with song');
      }
    } catch (error) {
      console.error('Error in saveSongWords:', error);
      throw error;
    }
  };
  const getSongWords = async (songId: number): Promise<Word[]> => {
    try {
      const response = await axios.post(
        `${API_URL}/wordsData`,
        {
          action: 'getSongWords',
          data: songId
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          withCredentials: false,
        }
      );

      if (response.data && response.data.success) {
        console.log("Song words retrieved successfully");
        return response.data.words;
      } else {
        console.log(response.data.message);
        return [];
      }
    } catch (error) {
      console.error('Error in getSongWords:', error);
      throw error;
    }
  };
  const getSpotifyToken = async (userId: number): Promise<string | null> => {
    try {
      const response = await axios.post(
        `${API_URL}/usersData`,
        { action: 'getSpotifyToken', data: userId },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          withCredentials: false,
        }
      );
      if (response.data && response.data.success) {
        return response.data.spotifyToken;
      }
    } catch (error) {
      console.error('Error getting Spotify token:', error);
    }
    return null;
  };
  const saveSpotifyToken = async (userId: number, token: string): Promise<void> => {
    try {
      await axios.post(
        `${API_URL}/usersData`,
        { action: 'saveSpotifyToken', data: { userId, spotifyToken: token } },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          withCredentials: false,
        }
      );
    } catch (error) {
      console.error('Error saving Spotify token:', error);
    }
  };
  return (
    <GlobalContext.Provider value={{
      user,
      setUser,
      handleSpotifyLoginSuccess,
      handleGoogleLoginSuccess,
      selectedTrack,
      setSelectedTrack,
      selectedLanguage,
      setSelectedLanguage,
      isMobile,
      speakWord,
      showSearch,
      setShowSearch,
      getUserPlaylists,
      getPlaylistTracks,
      generateLyricsSentences,
      getLyrics,
      generateLyricsVocabulary,
      getPexelsImageForWord,
      searchSong,
      allowedUsers,
      updateUser,
      spotifyToken,
      spotifyDeviceId,
      setSpotifyDeviceId,
      togglePlaySong,
      isPlaying,
      nowPlaying,
      setNowPlaying,
      playingTrackId,
      getSongData,
      saveSong,
      saveUserSong,
      updateSong,
      removeSongFromUser,
      saveWords,
      saveWordToUser,
      removeWordFromUser,
      updateWord,
      getTopTracksByCountry,
      getUserSongs,
      getUserWords,
      saveSongWords,
      getSongWords,
      theme,
      setTheme,
      // handleSpotifyCallback
    }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error('useGlobalContext must be used within a UserProvider');
  }
  return context;
};