import React, { useCallback, useEffect, useState } from 'react';
import { useGlobalContext } from '../contexts/GlobalContext';
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonList, IonItem, IonLabel, IonThumbnail, IonIcon, IonImg, IonSpinner } from '@ionic/react';
import { playCircleOutline, pauseCircleOutline, openOutline, eyeOutline, saveOutline, downloadOutline, gridOutline, addOutline, planetOutline, volumeHighOutline, arrowDown, chevronCollapseOutline, chevronDown, chevronUp, pause, play } from 'ionicons/icons';
import './SongsList.css';

import { Song, SpotifyTrack } from '../types/types.';
import { useHistory } from 'react-router';

export interface SongsListType {
    // spotifyId?: string;
    name: string;
    songs?: Song[];
    tracks?: SpotifyTrack[];
}



const SongsList: React.FC<SongsListType> = ({ name, songs, tracks }) => {
    const { user, saveSong, getSongData, updateSong, togglePlaySong, playingTrackId, isPlaying, saveSongWords, getLyrics, saveUserSong, generateLyricsVocabulary, selectedTrack, setSelectedTrack, saveWords } = useGlobalContext();
    const history = useHistory();
    const [showListItems, setShowListItems] = useState<boolean>(true);
    const navigateToItem = (spotifyId: string, type: 'song' | 'word') => {
        history.push(`/${type}/${spotifyId}`);
    };
    useEffect(() => {
        console.log("from SongsList tracks", tracks)

    }, [tracks]);
    const handleSaveSong = async (spotifyTrack: SpotifyTrack) => {
        console.log("handleSaveSong...", spotifyTrack)
        console.log("spotifyTrack: ", spotifyTrack)
        const songDetails = await getSongData(spotifyTrack.id);
        console.log("songDetails: ", songDetails)
        if (songDetails) {
            console.log(songDetails)
            //check if has lyrics, has words...
            //if not, generate and save in user.songs after all data will be collected.
        }
        else {
            const songObj = {
                id: -1,
                title: spotifyTrack.name,
                artist: '',
                audioPreviewUrl: spotifyTrack.preview_url,
                spotifyId: spotifyTrack.id,
                albumUrl: spotifyTrack.album.images[0]?.url,
                lyrics: "",

            }
            const response = await saveSong(songObj)
            if (response.success) {
                songObj.id = response.id
                const lyrics = await getLyrics(spotifyTrack)
                console.log(lyrics)
                songObj.lyrics = lyrics
            }
            const updateResponse = await updateSong(songObj)
            if (updateResponse.success) {
                const trackVocabulary = await generateLyricsVocabulary(songObj.lyrics)
                if (trackVocabulary.length) {
                    // const savedWords = saveWords(trackVocabulary)
                    const savedSongWords = saveSongWords(songObj.id, trackVocabulary)
                    console.log("savedSongWords:", savedSongWords)
                    // generate more words. need to implement logic for unique words count. 
                    if (user?.id) {
                        const insertUserSongResponse = saveUserSong({ userId: user?.id, songId: songObj.id })
                        console.log(insertUserSongResponse)
                    }
                }
                console.log("trackVocabulary: ", trackVocabulary)
            }
        }
    };
    const toggleListView = () => {
        setShowListItems(!showListItems)
        console.log("toggle")
    };
    return (
        <div className='default-card-wrapper'>
            <div className='d-flex justify-content-between align-items-center'>
                <p className='default-card__list-title'>{name}</p>
                <IonButton onClick={toggleListView} className='toggle-btn' fill='clear'>
                    <IonIcon icon={showListItems ? chevronUp : chevronDown} />
                </IonButton>
            </div>
            {showListItems && (
                <div className='default-card__list-wrapper'>
                    <IonList>
                        {songs && songs.length > 0 && songs.map((song) => (
                            <IonItem key={song.spotifyId}>
                                <div className='d-flex  default-card__content-right' style={{ width: '100%', padding: '8px' }}>
                                    <IonThumbnail style={{ heigth: '' }}>
                                        <img src={song.albumUrl} alt={song.title} />
                                    </IonThumbnail>
                                    <IonLabel style={{ width: '80%', cursor: 'pointer' }} onClick={() => navigateToItem(song.spotifyId, 'song')}>
                                        <div className='d-flex align-items-center' style={{ maxWidth: '150px' }}>
                                            <h2 className='f-bold' style={{ cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{song.title}</h2>
                                        </div>
                                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            <i>{song.artist}</i>
                                        </div>
                                    </IonLabel>
                                </div>
                                <IonButton onClick={() => togglePlaySong(song)} className='action-btn'>
                                    <IonIcon icon={playingTrackId === song.spotifyId && isPlaying ? pause : play} />
                                </IonButton>
                            </IonItem>
                        ))}
                        {tracks && tracks.length > 0 && tracks.map((track) => (
                            <IonItem key={track.id} >
                                <div className='d-flex  default-card__content-right' style={{ width: '100%', padding: '8px' }}>
                                    <IonThumbnail style={{ heigth: '' }}>
                                        <img src={track.album.images[0]?.url} alt={track.name} />
                                    </IonThumbnail>
                                    <IonLabel style={{ width: '80%', cursor: 'pointer' }}>
                                        <div className='d-flex align-items-center' style={{ maxWidth: '150px' }}>
                                            <h2 className='f-bold' style={{ cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{track.name}</h2>
                                        </div>
                                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            <i>{track.artists.map(artist => artist.name).join(', ')}</i>
                                        </div>
                                    </IonLabel>
                                </div>
                                <div className='action-btns'>
                                    <IonButton onClick={() => togglePlaySong(track)} className='action-btn'>
                                        <IonIcon icon={playingTrackId === track.id && isPlaying ? pause : play} />
                                    </IonButton>
                                    <IonButton onClick={() => handleSaveSong(track)} className='action-btn'>
                                        <IonIcon icon={addOutline} />
                                    </IonButton>
                                </div>
                            </IonItem>
                        ))}
                    </IonList>
                </div>
            )}
        </div>
    );
};

export default SongsList;

