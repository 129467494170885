import React, { useEffect, useState } from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonButton,
  IonIcon,
  useIonToast,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle
} from '@ionic/react';
import 'react-flagpack/dist/style.css'
import { checkmark, chevronDown, chevronUp, language, languageOutline } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '../contexts/GlobalContext';
import Flag from 'react-flagpack'
import "/node_modules/flag-icons/css/flag-icons.min.css";
import {Language} from '../types/types.'
// interface Language {
//   code: string;
//   name: string;
//   localName: string;
//   flag: string;
// }

export const languages: Language[] = [
  { id: 1, code: 'he-IL', name: 'Hebrew', localName: 'עברית', flag: 'il', isActive: true, languageCode: 'he', displayOrder: 1 },
  { id: 2, code: 'en-US', name: 'English (US)', localName: 'English (US)', flag: 'us', isActive: true, languageCode: 'en', displayOrder: 2 },
  { id: 3, code: 'es-ES', name: 'Spanish', localName: 'Español', flag: 'es', isActive: true, languageCode: 'es', displayOrder: 3 },
  { id: 4, code: 'fr-FR', name: 'French', localName: 'Français', flag: 'fr', isActive: true, languageCode: 'fr', displayOrder: 4 },
  { id: 5, code: 'de-DE', name: 'German', localName: 'Deutsch', flag: 'de', isActive: true, languageCode: 'de', displayOrder: 5 },
  { id: 6, code: 'it-IT', name: 'Italian', localName: 'Italiano', flag: 'it', isActive: true, languageCode: 'it', displayOrder: 6 },
  { id: 7, code: 'ja-JP', name: 'Japanese', localName: '日本語', flag: 'jp', isActive: true, languageCode: 'ja', displayOrder: 7 },
  { id: 8, code: 'ko-KR', name: 'Korean', localName: '한국어', flag: 'kr', isActive: true, languageCode: 'ko', displayOrder: 8 },
  { id: 9, code: 'zh-CN', name: 'Chinese (Simplified)', localName: '简体中文', flag: 'cn', isActive: true, languageCode: 'zh', displayOrder: 9 },
  { id: 10, code: 'ru-RU', name: 'Russian', localName: 'Русский', flag: 'ru', isActive: true, languageCode: 'ru', displayOrder: 10 },
  { id: 11, code: 'ar-SA', name: 'Arabic', localName: 'العربية', flag: 'sa', isActive: true, languageCode: 'ar', displayOrder: 11 },
  { id: 12, code: 'en-GB', name: 'English (UK)', localName: 'English (UK)', flag: 'gb', isActive: true, languageCode: 'en', displayOrder: 12 },
];

const LanguageSelector: React.FC = () => {
  const {user, selectedLanguage, setSelectedLanguage, updateUser } = useGlobalContext();
  const [showLanguageSelector, setShowLanguageSelector] = useState<boolean>(false);

  // useEffect(() => {
  //   const savedLanguage = localStorage.getItem('selectedLanguage');
  //   if (user?.languageId) {
  //     setSelectedLanguage(savedLanguage);
  //   }
  // }, [setSelectedLanguage]);

  const handleLanguageChange = (event: CustomEvent) => {
    const newLanguage = event.detail.value;
    // setSelectedLanguage(newLanguage);
    updateUser("updateUser", {field: "languageId", value: newLanguage, userId: user?.id })
    // localStorage.setItem('selectedLanguage', newLanguage);
    setShowLanguageSelector(false);
  };

  return (
    <>
      <IonItem button onClick={() => setShowLanguageSelector(!showLanguageSelector)}>
        <IonLabel>
          <div className='d-flex'>
            <span className={`fi fi-${languages.find(lang => lang.id === user?.languageId)?.flag}`}></span>
            <h2 style={{margin: '0 8px'}}>{languages.find(lang => lang.id === user?.languageId)?.localName}</h2>
          </div>
        </IonLabel>
        <IonIcon icon={language} slot="start" />
      </IonItem>
      {showLanguageSelector && (
        <IonRadioGroup value={user?.languageId || 1} onIonChange={handleLanguageChange}>
          <IonList>
            {languages.map((lang) => (
              <IonItem key={lang.id}>
                <IonLabel>
                  <p>{lang.localName}</p>
                  <span className={`fi fi-${lang.flag}`}></span>
                </IonLabel>
                <IonRadio slot="end" value={lang.id} />
              </IonItem>
            ))}
          </IonList>
        </IonRadioGroup>
      )}
    </>
  );
};

export default LanguageSelector;