import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel, IonRouterOutlet, IonPage, IonContent } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { home, search, bookOutline, person } from 'ionicons/icons';
import { useGlobalContext } from './contexts/GlobalContext';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import Player from './components/Player';
import Home from './pages/Home';
import Login from './pages/Login';
import UserPage from './pages/UserPage';
import SavedWords from './pages/SavedWords';
import Search from './pages/Search';
import SongPage from './pages/SongPage';
import SongDetails from './components/SongDetails';
import NowPlaying from './components/NowPlaying';
import SpotifyCallback from './components/SpotifyCallback';
import AppHeader from './components/AppHeader';
const AppContent: React.FC = () => {
  document.body.className = 'theme-ocean-breeze'
  const { user, handleSpotifyLoginSuccess, handleGoogleLoginSuccess, spotifyDeviceId, spotifyToken } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('authToken');
      const tokenProvider = localStorage.getItem('authTokenProvider');
      if (token) {
        if (tokenProvider === 'spotify') {
          handleSpotifyLoginSuccess(token);
        } else if (tokenProvider === 'google') {
          handleGoogleLoginSuccess(token);
        }
      }
      setIsLoading(false);
    };
    checkAuth();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <IonReactRouter>
      <AppHeader />
      <IonTabs>
        <IonRouterOutlet>
          <AuthenticatedRoute exact path="/home" component={Home} />
          <Route exact path="/login" component={Login} />
          <AuthenticatedRoute exact path="/UserPage" component={UserPage} />
          <AuthenticatedRoute exact path="/SavedWords" component={SavedWords} />
          <AuthenticatedRoute exact path="/search" component={Search} />
          <AuthenticatedRoute exact path="/song/:spotifyId" component={SongPage} />
          <Route exact path="/">
            <Redirect to={user ? "/home" : "/login"} />
          </Route>
          {/* <Route exact path="/spotifyCallback" component={SpotifyCallback} /> */}
        </IonRouterOutlet>

        <IonTabBar slot="bottom" className="hide-on-desktop tab-bar" style={{ display: user ? 'flex' : 'none' }}>
          <IonTabButton tab="home" href="/home">
            <IonIcon icon={home} />
            <IonLabel>Home</IonLabel>
          </IonTabButton>
          <IonTabButton tab="search" href="/search">
            <IonIcon icon={search} />
            <IonLabel>Search</IonLabel>
          </IonTabButton>
          <IonTabButton tab="savedWords" href="/SavedWords">
            <IonIcon icon={bookOutline} />
            <IonLabel>Saved</IonLabel>
          </IonTabButton>
          <IonTabButton tab="userPage" href="/UserPage">
            <IonIcon icon={person} />
            <IonLabel>Profile</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
      {user && <NowPlaying />}
      {!spotifyDeviceId && spotifyToken && <Player token={spotifyToken} />}
    </IonReactRouter>
  );
};

export default AppContent;