import React, { useEffect, useState } from 'react';
import { IonToolbar, IonTitle, IonButtons, IonButton, IonImg, IonSearchbar, IonList, IonItem, IonLabel, IonText, IonHeader, IonMenuButton, IonIcon, isPlatform, IonCardTitle } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '../contexts/GlobalContext';
import axios from 'axios';
import { navigate, home, list, search, person } from 'ionicons/icons';
import SearchBar from './SearchBar';


const AppHeader: React.FC = () => {
    const history = useHistory();
    const { user, isMobile, showSearch, theme } = useGlobalContext();
    useEffect(() => {
        console.log("from header. user: ", user)
    }, [])
    const handleLogoClick = () => {
        history.push('/home');
    };

    return (
        <div className={isMobile && showSearch ? 'header-wrapper mobile-view' : 'header-wrapper'}>
            <IonHeader>
                <IonToolbar>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex logo-wrapper' onClick={handleLogoClick}>
                            <div className='logo'>
                                <IonButton fill="clear" >
                                    <IonImg src={theme === "custom" ? '/assets/logo2.png' : theme === 'dark' ? '/assets/logo2.png' : '/assets/logo2.png'} alt="Logo" />
                                </IonButton>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>

                            {!isMobile && (
                                <>
                                    <IonButton onClick={() => history.push('/search')}>
                                        <IonIcon icon={search} />
                                    </IonButton>
                                    <IonButton onClick={() => history.push('/UserPage')}>
                                        <IonIcon icon={person} />
                                    </IonButton>
                                </>

                            )}
                        </div>
                    </div>
                    {/* <IonButtons slot="end" style={{ color: 'white' }}>
                </IonButtons> */}
                    {/* <IonCardTitle className='default-card__title'>{'Good ' + getTimeOfDay() + ` ${user?.firstName || user?.displayName || 'friend'}!`}</IonCardTitle> */}
                </IonToolbar>
            </IonHeader>
        </div>
    );
};

export default AppHeader;