import React, { useEffect, useState } from 'react';
import './Home.css';
import Playlist from '../components/Playlist';
import DefaultPage from '../components/DefaultPage';
import { RouteComponentProps } from 'react-router-dom';
import { IonCard, IonCardHeader, IonCardTitle, IonList, IonItem, IonIcon, IonLabel, IonCol, IonContent } from '@ionic/react';
import { personCircle, logOut } from 'ionicons/icons';
import LanguageSelector from '../components/LanguageSelector';
import { useGlobalContext } from '../contexts/GlobalContext';
import SongsList from '../components/SongsList';
import WordsList from '../components/WordsList';
import { Song, Word, SpotifyTrack, SpotifyPlaylist } from '../types/types.';
const Home: React.FC<RouteComponentProps> = (props) => {
  const { user, getTopTracksByCountry, setShowSearch, getUserSongs } = useGlobalContext();
  const [userSongs, setUserSongs] = useState<Song[] | null>(null);
  
  
  useEffect(() => {
    fetchUserSongs();
    // setShowSearch(true)
  }, []);

  const fetchUserSongs = async () => {
    if (user && user.id) {
      const songs = await getUserSongs(user.id);
      if (songs) {
        setUserSongs(songs)
      }
    }
  };

  return (
    <>
      <DefaultPage>
        <IonCard className="default-card">
            <IonContent>
              {userSongs && userSongs.length > 0 && <SongsList name="Saved Songs" songs={userSongs} />}
            </IonContent>
        </IonCard>
      </DefaultPage>
    </>
  );
};

export default Home;
