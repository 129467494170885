import React, { useRef, useEffect, useState } from 'react';
import { IonButton, IonContent, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonModal } from '@ionic/react';
import './WordsContainer.css';
import WordCard from './WordCard';
import { closeOutline } from 'ionicons/icons';
import { SwiperSlide } from 'swiper/react';
import CustomSwiper from './CustomSwiper';
import { User, Word } from '../types/types.'
interface WordsModalProps {
  words: Word[];
  showModal: boolean;
  closeModal: ()=>void;
}

const WordsModal: React.FC<WordsModalProps> = ({ words, showModal, closeModal }) => {
  useEffect(() => {
    console.log(words)
  }, [words]);

  return (
    <IonModal isOpen={showModal} onDidDismiss={closeModal} className='words-modal'>
      <IonContent className="ion-padding">
        <CustomSwiper>
          {words.map((word, index) => (
            <SwiperSlide key={index}>
              <WordCard word={word} previewMode='' />
            </SwiperSlide>
          ))}
        </CustomSwiper>
      </IonContent>
      <IonButton className='modal-x-btn' onClick={closeModal}>
        <IonIcon icon={closeOutline} />
      </IonButton>
    </IonModal>
  );
};

export default WordsModal;