import React, { useCallback, useEffect, useState } from 'react';
import { useGlobalContext } from '../contexts/GlobalContext';
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonList, IonItem, IonLabel, IonThumbnail, IonIcon, IonImg, IonSpinner } from '@ionic/react';
import { playCircleOutline, pauseCircleOutline, openOutline, eyeOutline, saveOutline, downloadOutline, gridOutline, addOutline, planetOutline, volumeHighOutline } from 'ionicons/icons';
import './SongsList.css';

import { Word } from '../types/types.';

export interface WordsListType {
    name: string;
    words: Word[];
}

const WordsList: React.FC<WordsListType> = ({ name, words }) => {
    const { user, getUserSongs, getPlaylistTracks, speakWord, togglePlaySong, playingTrackId, isPlaying, saveWordToUser, getLyrics, generateLyricsVocabulary, selectedTrack, setSelectedTrack, saveWords } = useGlobalContext();

    useEffect(() => {
        console.log("from WordsList ",)

    }, []);

    const saveWord1 = (word: Word) => {
        if (word.id > 0 && user && user.id > 0) {
            saveWordToUser({ userId: user.id, wordId: word.id })
        }
    }
    return (
        <div className='default-card__list-wrapper'>
            <IonList>
                {words.map((word) => (
                    <IonItem key={word.id} className="word-item">
                        <IonThumbnail slot="start">
                            <img src={word.image} alt={word.sourceWord} />
                        </IonThumbnail>
                        <IonLabel>
                            <div className='d-flex align-items-center'>
                                <h2>{word.sourceWord}</h2>
                                <IonIcon
                                    style={{ cursor: 'pointer' }}
                                    icon={playCircleOutline}
                                    onClick={() => speakWord(word.sourceWord, 'it')}
                                />
                            </div>
                            <p>{word.translation}</p>
                        </IonLabel>
                        {!word.id && (
                            <IonButton onClick={() => saveWord1(word)} fill='clear' size='small' className='words-modal-btn'>
                                <IonIcon icon={addOutline} />
                            </IonButton>)}
                    </IonItem>
                ))}
            </IonList>
        </div>
    );
};

export default WordsList;

