import React, { useEffect } from 'react';
import { IonButton, IonContent, IonImg, IonPage } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '../contexts/GlobalContext';

const Login: React.FC = () => {
    const history = useHistory();
    const { user, allowedUsers, handleSpotifyLoginSuccess, handleGoogleLoginSuccess } = useGlobalContext();

    useEffect(() => {
        console.log("from login page. user: ", user)
        if (user && allowedUsers?.includes(user.email)) {
            history.replace('/home');
        }
        else {
            console.log("auth failed")
            return
        }
    }, [user, allowedUsers, history]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.hash.slice(1));
        const accessToken = params.get('access_token');
        const state = params.get('state');
        if (accessToken) {
            if (state === 'spotify') {
                handleSpotifyLoginSuccess(accessToken);
            } else if (state === 'google') {
                handleGoogleLoginSuccess(accessToken);
            }
        }
    }, []);

    const handleGoogleLogin = () => {
        const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";
        const GOOGLE_REDIRECT_URI = process.env.REACT_APP_URL +'/login';
        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${encodeURIComponent(GOOGLE_REDIRECT_URI)}&response_type=token&scope=email profile&state=google`;
        window.location.href = authUrl;
    };

    const handleSpotifyLogin = () => {
        const SPOTIFY_CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID || "";
        const SPOTIFY_REDIRECT_URI = process.env.REACT_APP_URL +'/login';
        const scopes = 'playlist-read-private user-read-private user-read-email user-read-playback-state user-modify-playback-state streaming user-library-read';
        const authUrl = `https://accounts.spotify.com/authorize?client_id=${SPOTIFY_CLIENT_ID}&redirect_uri=${encodeURIComponent(SPOTIFY_REDIRECT_URI)}&scope=${encodeURIComponent(scopes)}&response_type=token&state=spotify`;
        window.location.href = authUrl;
    };
    
    return (
        <IonPage>
            <IonContent className="ion-padding">
                <div className="login-container">
                    <h1>Welcome to SingSmart</h1>
                    <div className='login-logo-wrapper'>
                        <IonImg src='/assets/app-logo.png' alt="Logo" />
                    </div>
                    
                    <IonButton expand="block" onClick={handleSpotifyLogin} className='spotify-btn'>
                        <div style={{ height: '20px', width: '20px', marginRight: '8px' }}>
                            <IonImg src='/assets/icon/spotify_white.png' alt="spotifyLogo" />
                        </div>
                         Spotify Login
                    </IonButton>
                    <IonButton expand="block" onClick={handleGoogleLogin} className='google-btn'>
                        <div style={{ height: '20px', width: '20px', marginRight: '8px' }}>
                            <IonImg src='/assets/icon/google.svg' alt="googleLogo" />
                        </div>
                        Google Login
                    </IonButton>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Login;