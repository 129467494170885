import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../contexts/GlobalContext';

const Player: React.FC<{ token: string }> = ({ token }) => {
const { setSpotifyDeviceId } = useGlobalContext();
  const [player, setPlayer] = useState<Spotify.Player | null>(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    // Define the global callback function before the SDK is loaded
    window.onSpotifyWebPlaybackSDKReady = () => {
      const spotifyPlayer = new window.Spotify.Player({
        name: 'My Ionic App Player',
        getOAuthToken: (cb: (token: string) => void) => {
          cb(token);
        },
        volume: 0.5,
      });

      // Add listener for 'ready' event
      spotifyPlayer.addListener('ready', (event:any) => {
        if ('device_id' in event) {
          // Narrowing the type: ensure we have a SpotifyReadyEvent
          console.log('Player is ready with Device ID', event.device_id);
          setSpotifyDeviceId(event.device_id)
          setIsReady(true);
          setPlayer(spotifyPlayer);
        }
      });

      spotifyPlayer.connect();
    };

    // Load the Spotify SDK
    const script = document.createElement('script');
    script.src = 'https://sdk.scdn.co/spotify-player.js';
    script.async = true;
    document.body.appendChild(script);

    // Clean up the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, [token]);

  return (
    null
  );
};

export default Player;
