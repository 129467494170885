import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useGlobalContext } from '../contexts/GlobalContext';

interface AuthenticatedRouteProps extends Omit<RouteProps, 'component'> {
  component: React.ComponentType<any>;
}

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({ component: Component, ...rest }) => {
  const { user } = useGlobalContext();
  
  return (
    <Route 
      {...rest}
      render={(props) => 
        user ? <Component {...props} /> : <Redirect to={{pathname: "/login", state: { from: props.location }}} />
      }
    />
  );
};

export default AuthenticatedRoute;