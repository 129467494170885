import React, { useEffect, useState } from 'react';
import { IonAvatar, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenuToggle, IonPage, IonThumbnail, IonTitle, IonToolbar } from '@ionic/react';
import AppHeader from '../components/AppHeader';
import './Home.css';
import Playlist from '../components/Playlist';
import DefaultPage from '../components/DefaultPage';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useGlobalContext } from '../contexts/GlobalContext';
import { contrastOutline, helpCircle, languageOutline, logOut, musicalNotes, personCircle, settings, statsChart } from 'ionicons/icons';
import SearchBar from '../components/SearchBar';
import SongsList from '../components/SongsList';
import { SpotifyTrack, SpotifyPlaylist } from '../types/types.';

const Search: React.FC<RouteComponentProps> = (props) => {
  const { user, selectedTrack, spotifyToken, getUserPlaylists, setShowSearch, getTopTracksByCountry } = useGlobalContext();
  const [top50Italy, setTop50Italy] = useState<SpotifyTrack[] | null>(null);
  const [userPlaylists, setUserPlaylists] = useState<SpotifyPlaylist[] | null>(null);
  useEffect(() => {
    setShowSearch(true)
    fetchUserPlaylists();
    fetchTopTracksByCountry();
  }, []);
  
  const fetchUserPlaylists = async () => {
    if (user && user.spotifyId) {
      if (spotifyToken) {
        const userPlaylists = await getUserPlaylists(spotifyToken);
        console.log(userPlaylists)
        setUserPlaylists(userPlaylists);
      }
    }
  };
  const fetchTopTracksByCountry = async () => {
    if (user && user.id) {
      const topTracksByCountry = await getTopTracksByCountry();
      setTop50Italy(topTracksByCountry)
    }
  };
  return (
    <>
      <DefaultPage>
      <SearchBar />
        {/* {selectedTrack && selectedTrack.words?.length && (<TrackCard track={selectedTrack} />)} */}
        <IonCard className="default-card">
            {/* <IonContent> */}
              {userPlaylists && userPlaylists.length > 0 && <Playlist playlists={userPlaylists} />}
              {top50Italy && top50Italy.length > 0 && <SongsList name="Top 50 Italy" tracks={top50Italy} />}
            {/* </IonContent> */}
        </IonCard>
      </DefaultPage>
    </>
  );
};

export default Search;
