const prod = {
    apiUrl: 'https://o9cbszuryh.execute-api.eu-north-1.amazonaws.com',
    appUrl: 'https://singsmart.fleximind.ai',
    pexelsApiKey: 'HBsqEnKPmOg2AvYdF5l5WeJHoXRXS504a2wImh6ANGOOIPGevxmdregl'
  };
  
  const dev = {
    apiUrl: 'https://o9cbszuryh.execute-api.eu-north-1.amazonaws.com',
    appUrl: 'http://localhost:8100',
    pexelsApiKey: 'HBsqEnKPmOg2AvYdF5l5WeJHoXRXS504a2wImh6ANGOOIPGevxmdregl'
  };
  console.log("env: ", process.env.NODE_ENV)
  export const config = process.env.NODE_ENV === 'production' ? prod : dev;